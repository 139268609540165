import React from 'react';
//import Router from './jsx/index';
import Index9 from "./jsx/pages/home9";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/plugins.css';
import './css/style.css';
import './css/templete.css';


function App() {
  return (
    <div className="App">
      {/* <Router /> */}
    <Index9 />
    </div>
  );
}

export default App;
