import React, { useState } from 'react';

function Waitlist() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [message, setMessage] = useState("");

    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('api_token', 'ba71f7f2d1b2d75c1a03b78b396ef080');
            formData.append('list_id', '30829');
            formData.append('email', email);
            formData.append('first_name', name);
            formData.append('mobile', mobileNumber)

            let res = await fetch("https://api.mailketing.co.id/api/v1/addsubtolist", {
                method: "POST",
                body: formData,
            });
            let resJson = await res.json();
            console.log(resJson)
            if (resJson.status === true) {
                setName("");
                setEmail("");
                setMobileNumber("");
                setMessage("Data kamu sudah berhasil disimpan. Silahkan cek email inbox / spam folder kamu!");
            } else {
                //console.log(res);
                setMessage(resJson.response);
            }
        } catch (err) {
            console.log("Error:")
            console.log(err);
        }
    };

    return (
        <div className="modal fade subscribe-box" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">

            <div className="modal-dialog" role="document">

                <form className="dzSubscribe modal-content" onSubmit={handleSubmit}>
                    <div className="modal-header">
                        <img src="images/bell.png" alt="" />
                        <h5 className="modal-title" id="exampleModalLabel">Daftar Waiting List</h5>
                        <p>Lengkapi formulir dibawah untuk mendapatkan notifikasi saat Lovepreneur The Movie akan tayang!</p>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <input
                                required="required"
                                className="form-control"
                                type="text"
                                value={name}
                                placeholder="Nama Lengkap"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                required="required"
                                className="form-control"
                                type="email"
                                value={email}
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group">

                            <input
                                required="required"
                                className="form-control"
                                type="number"
                                value={mobileNumber}
                                placeholder="Nomor WhatsApp"
                                onChange={(e) => setMobileNumber(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="modal-footer">

                        <button type="submit" className="site-button gradient radius-md button-lg shadow">Join Sekarang</button>
                    </div>

                    <div className="message">{message ? <p>{message}</p> : null}</div>
                </form>
            </div >
        </div >
    );
}

export default Waitlist;
