import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
//import SideBox from '../element/sidebox';
//import Popup from '../element/popup';
import Waitlist from '../element/waitlist';

// import '../../css/plugins.css';
// import '../../css/style.css';
// import '../../css/templete.css';
import '../../css/skin/skin-9.css';
import logo from "../../images/logo.png";
import tagLine from "../../images/tagline.png"

class Index9 extends Component {
    componentDidMount() {
        window.Frost.init();
        window.Frost.load();
        window.pparticlejs();
        //window.handleSideBarMenu();
    }

    render() {

        return (
            <>

                <div className="dez-coming-soon style-9 wow fadeIn" id="particles-js" data-wow-duration="0.80s" data-wow-delay="0.50s" style={{ "backgroundImage": "url(images/background/bg6.jpg)" }}>
                    <div className="clearfix dez-coming-bx">
                        <div className="logo wow fadeInUp bottom-left" data-wow-duration="1.5s" data-wow-delay="1.0s">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="dez-content top-left center-md">
                            <div className="countdown">
                                <div className="date wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.0s">
                                    <span className="days time"></span>
                                    <span>Hari</span>
                                </div>
                                <div className="date wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.3s">
                                    <span className="hours time"></span>
                                    <span>Jam</span>
                                </div>
                                <div className="date wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.6s">
                                    <span className="mins time"></span>
                                    <span>Menit</span>
                                </div>
                                <div className="date wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.9s">
                                    <span className="secs time"></span>
                                    <span>Detik</span>
                                </div>
                            </div>
                            <h2 className="dez-title ml2 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.5s">Nobar Online <br />Lovepreneur The Movie</h2>
                            <div className="dez-coming-btn wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="2.2s">
                                {/* <a href="javascript:void(0);" className="site-button openbtn button-lg m-r10">Get In Touch</a> */}
                                <a data-toggle="modal" data-target="#exampleModal" className="site-button button-lg outline white">Daftar Waiting List</a>
                                {/* <img src={tagLine} alt="tagline" /> */}
                            </div>
                        </div>
                        <div className="text-right bottom-right">
                            <ul className="dez-social-icon social-2">
                                <li className="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay="1.0s"><a href="https://www.youtube.com/user/TeladanCinta" target="_blank"><i className="fa fa-youtube"></i><span>Youtube</span></a></li>
                                <li className="wow fadeInRight" data-wow-duration="1.5s" data-wow-delay="1.6s"><a href="https://www.instagram.com/lovepreneur.id/" target="_blank"><i className="fa fa-instagram"></i><span>Instagram</span></a></li>
                            </ul>

                        </div>
                    </div>
                </div>

                <Waitlist />
            </>
        )
    }
}

export default Index9;